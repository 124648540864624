import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Switch,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import queryString from 'query-string';

import {
  closeSaveQuoteModalConfirmation,
  loadMultiModalData,
  saveSelectedAddPetModal,
  saveSelectedEditPetModal,
  validateEmployerId,
} from '../../../actions';

import { YourInfoContent } from '../yourInfo/YourInfoContent';
import { MODAL_RELATION_LIST, QuotingSteps } from '../../../constants';
import LiveVetInfoScreen from '../../liveVetInfo/LiveVetInfoScreen';
import LiveVetContextRoot from '../../contexts/LiveVetContext';
import ModalContainer from '../../common/ModalContainer';
import { ModalAddPet } from '../../common/modalAddPet/ModalAddPet';
import ModalRemovePet from '../ModalRemovePet';
import MultiModalInfo from '../../common/MultiModalInfo';
import { SaveYourQuoteModal } from '../SaveYourQuoteModal';
import SidebarSaveQuoteModalConfirmation
from '../SidebarSaveQuoteModalConfirmation';
import { PriceStickyBar } from '../PriceStickyBar';
import { QuoteContent } from '../QuoteContent';
import { FigoLoadingOverlay } from '../../common/FigoLoadingOverlay';
import {
  createEffectiveDate,
  forceFocusElement,
  formatParameters,
} from '../../../util';
import { DuplicatedPurchasesModal } from '../purchase/duplicatedPurchasesModal';

import './css/QuotingHeader.css';
import { Header } from './Header';
import { useRedesign } from '../../../hooks';
import { dataLayerViewItem } from '../../../gtmDataLayer/dataLayerViewItem';

const {
  NEED_TO_KNOW_MODAL_ID,
  PET_CO_PAY_MODAL_ID,
  TERMS_AND_COND_MODAL_ID,
  WELLNESS_MODAL_ID,
} = MODAL_RELATION_LIST;

function QuotingHeader() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const isRedesign = useRedesign();
  const parametersRaw = queryString.parse(location.search);
  const parameters = formatParameters(parametersRaw);
  const store = useSelector(({ quoting }) => quoting);
  const {
    addPetModalVisible,
    currentQuoteStep,
    customerZipCode,
    editPetModalVisible,
    employerIdValidated,
    isLoading,
    multiModalData,
    multiModalUserSelection,
    removePetModalVisible,
    saveQuote,
  } = store;

  const {
    needToKnowIdSelected,
    needToKnowModalVisible,
    petCoPayIdSelected,
    petCoPayModalVisible,
    termsAndCondIdSelected,
    termsAndCondModalVisible,
    wellnessIdSelected,
    wellnessModalVisible,
  } = multiModalUserSelection || {};
  const { savedQuoteModalVisible } = saveQuote || {};

  const styles = useMemo(() => {
    const isSecondStep = currentQuoteStep === QuotingSteps.YourInfo.value;
    if (Object.entries(parametersRaw).length || isSecondStep) {
      return {
        bodyStyle: 'App-body-quote',
      };
    }

    return {
      bodyStyle: '',
    };
  }, [currentQuoteStep, parametersRaw]);

  const showLoadingOverlay = useMemo(() => isLoading
    || store.isLoadingNewAddress
    || store.purchase.loading
    || store.rateSummary.loading
    || store.sessionInformation.isLoading, [
    isLoading,
    store.isLoadingNewAddress,
    store.purchase.loading,
    store.rateSummary.loading,
    store.sessionInformation.isLoading,
  ]);

  useEffect(() => {
    if (parameters.empGuid && !employerIdValidated) {
      dispatch(validateEmployerId(parameters.empGuid));
    }
  }, [employerIdValidated, parameters.empGuid, dispatch]);

  const handleCloseModalAddPet = useCallback(() => {
    dispatch(saveSelectedEditPetModal(false));
    dispatch(saveSelectedAddPetModal(false));
    const { petQuoteSelected, data: { ebPetQuoteResponseList } } = store;
    const currentQuote = (ebPetQuoteResponseList || [])
      .find((quote) => quote.petQuoteId === petQuoteSelected);
    forceFocusElement(isRedesign
      ? `pet-${currentQuote.petQuoteId}`
      : `petQuoteDropDownItem${currentQuote.petQuoteId}-header`);
  }, [dispatch, isRedesign, store]);

  const handleOnClickCloseConfirmationModal = useCallback(() => {
    dispatch(closeSaveQuoteModalConfirmation());
  }, [dispatch]);

  useEffect(() => {
    if (!customerZipCode || customerZipCode === multiModalData?.ZipCode) {
      return;
    }

    dispatch(loadMultiModalData({
      effectiveDate: createEffectiveDate(),
      isEBProduct: true,
      zipCode: customerZipCode,
    }));
  }, [customerZipCode, dispatch, multiModalData]);

  // GTM
  useEffect(() => {
    dataLayerViewItem({
      pathname: location.pathname,
      petIndex: store.petQuoteSelected,
    });
  }, [location.pathname, store.petQuoteSelected]);

  return (
    <>
      <Header />

      <main className={`App-body ${styles.bodyStyle}`}>
        <Switch>
          <Route exact path={match.path}>
            <QuoteContent />
          </Route>

          <Route path={`${match.path}/yourInfo/:scrollToTop?`}>
            <YourInfoContent showLoadingOverlay={showLoadingOverlay} />
          </Route>

          <Route path={`${match.path}/liveVetInfo/:scrollToTop?`}>
            <LiveVetContextRoot.Provider>
              <LiveVetInfoScreen />
            </LiveVetContextRoot.Provider>
          </Route>
        </Switch>
      </main>

      <PriceStickyBar />

      <DuplicatedPurchasesModal />

      <ModalContainer
        cancelable={false}
        idPrefix="addAnotherPetModal"
        keepInDOM
        show={addPetModalVisible || editPetModalVisible}
      >
        <ModalAddPet
          handleCloseModalAddPet={handleCloseModalAddPet}
          isEditing={editPetModalVisible}
          show={addPetModalVisible || editPetModalVisible}
        />
      </ModalContainer>

      <ModalContainer
        cancelable={false}
        show={removePetModalVisible}
      >
        <ModalRemovePet />
      </ModalContainer>

      <MultiModalInfo
        id="seeWhatWeCover"
        keepInDOM
        menuSelected={needToKnowIdSelected}
        modalId={NEED_TO_KNOW_MODAL_ID}
        show={needToKnowModalVisible}
      />

      <MultiModalInfo
        id="termsAndCondition"
        menuSelected={termsAndCondIdSelected}
        modalId={TERMS_AND_COND_MODAL_ID}
        show={termsAndCondModalVisible}
      />

      <MultiModalInfo
        id="powerUp70428" // wellness
        keepInDOM
        menuSelected={wellnessIdSelected}
        modalId={WELLNESS_MODAL_ID}
        show={wellnessModalVisible}
      />

      {/* FPA-11163 */}
      <MultiModalInfo
        id="petCopayContent"
        menuSelected={petCoPayIdSelected}
        modalId={PET_CO_PAY_MODAL_ID}
        show={petCoPayModalVisible}
      />

      <MultiModalInfo
        id="powerUp70422"// vet exam fees
        keepInDOM
        menuSelected={-1}
        show={store.multiModalCustomData}
      />

      <SaveYourQuoteModal />

      <SidebarSaveQuoteModalConfirmation
        handleOnClick={handleOnClickCloseConfirmationModal}
        show={savedQuoteModalVisible}
      />

      <FigoLoadingOverlay visible={showLoadingOverlay} />

      {/* OneInc modal create here */}
      <div id="portalOneContainer" />
    </>
  );
}

export { QuotingHeader };
