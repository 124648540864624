import React from 'react';
import { useDispatch } from 'react-redux';

import { useTranslations } from '../../hooks';
import { needToKnowModalVisibleChanged } from '../../actions/quoting';
import './SeeFullCoverage.css';

const SeeFullCoverage = ({ isVisible = true }) => {
  const { t } = useTranslations('quoting', 'fullCoverage');
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(
      needToKnowModalVisibleChanged({ visible: true }),
    );
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="See-full-coverage-details-container">
      <button
        aria-controls="seeWhatWeCovermodalContainer"
        onClick={handleButtonClick}
        type="button"
      >
        <h2 className="See-full-coverage-details-title">
          {t('title')}
        </h2>
      </button>

      <div
        className="See-full-coverage-details-underline"
        role="presentation"
      />
    </div>
  );
};

export { SeeFullCoverage };
